import React, { useEffect, useMemo, useRef, useState } from 'react'
import './physical-exam.scss'
import { del, delWithBody, get, put } from '../../../utils/fetchAPI'
import CustomModal from '../../common/modal/modal'
import TemplateList from './template-list'
import PhysicalExamAdd from './physical-exam-add'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prescriptionAddSubDocument, prescriptionAddSubDocumentArray, prescriptionRemoveSubDocument, prescriptionUpdateSubDocument } from '../../../service/api/prescription'
import { faEdit, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import { showSuccessToast } from '../../../utils/toaster-alert'
import { useSelector } from 'react-redux'
import PrescriptionHeader from '../prescription-print/prescriptionHeader'
import ReactToPrint from 'react-to-print'
import { PhysicalExamPrint } from './physical-exam-print'

const PhysicalExam = ({ patientId, visitDetails, patientDetails, updateState }) => {
    let [doctorPhysicalExamList, setDoctorPhysicalExamList] = useState([])
    const [listLoading, setListLoading] = useState(false)
    const [templateShow, setTemplateShow] = useState(false)
    const [physicalExamAdd, setPhysicalExamAdd] = useState(false)
    const [updatePhysicalExam, setUpdatePhysicalExam] = useState({})
    const [selectedPhysicalExam, setSelectedPhysicalExam] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [patientPhysicalExams, setPatientPhysicalExams] = useState(visitDetails?.physicalExams)
    const timeoutIdRef = useRef()
    const [doctorDetails, setDoctorDetails] = useState([])
    const { user } = useSelector((state) => state.auth);
    const [isHeader, setIsHeader] = useState(true)
    const printRef = useRef()
    
    useEffect(() => {
        getDoctorPhysicalExamList()
    }, [visitDetails?.doctor])
    const getDoctorPhysicalExamList = async () => {
        if (visitDetails?.doctor) {
            await get(`physical-exam-list/${visitDetails?.doctor}`)
                .then((res) => {
                    if (res?.status === 200) {
                        setDoctorPhysicalExamList(res?.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setListLoading(false)
                })
                .finally(() => {
                    setListLoading(false)
                }
                );
        }
    }

    useEffect(() => {

        if (user?.user?.type?.toLowerCase() === 'doctor') {
            setDoctorDetails(user?.user)
            setIsHeader(user?.user.details?.config?.prescriptionHeaderInPrint)
        }
        else if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
    }, [])

    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleOptions = (option, physicalExam, index) => {
        setSelectedPhysicalExam(physicalExam)
        if (option?.toLowerCase() == "normal") {
            // setTextAreaValue(physicalExam?.defaultNormalDesc, physicalExam?._id)
            handlePatientPhysicalExam("text-area", [], physicalExam, physicalExam?.defaultNormalDesc)
        }
        else if (option?.toLowerCase() == "notexamed") {
            setTextAreaValue("", physicalExam?._id)
            setTextAreaValue("No exam", physicalExam?._id, "placeholder")
            removePhysicalExam(physicalExam?._id)
        }
        else if (option?.toLowerCase() == "abnormal") {
            setTextAreaValue("", physicalExam?._id)
            setTextAreaValue("Write or select from templates", physicalExam?._id, "placeholder")
            // let checkAlreadyAdded = patientPhysicalExams?.findIndex(obj => obj?.physicalExam === physicalExam?._id);
            // if (checkAlreadyAdded != -1) {
            //     handlePatientPhysicalExam("text-area", [], physicalExam, patientPhysicalExams[checkAlreadyAdded]['desc'])
            // }
            handlePatientPhysicalExam("text-area", [], physicalExam, "")

        }

    }
    const showTemplates = (physicalExam = [], index) => {
        let checkBox = document.getElementById(`abnormal-${physicalExam?._id}`)
        setSelectedPhysicalExam(physicalExam)
        if (checkBox) {
            setTextAreaValue("", physicalExam?._id)
            setTextAreaValue("Write or select from templates", physicalExam?._id, "placeholder")
            checkBox.checked = true
        }

        setTemplateShow(true)
    }

    const setTextAreaValue = (value, id, type = "value", disabled = false) => {
        const textArea = document.getElementById(`textArea-${id}`)
        if (disabled && textArea) {
            textArea.disabled = true
        }
        if (textArea) {
            if (type == "value") textArea.value = value
            else if (type == "placeholder") textArea.placeholder = value
        }
    }

    const setRadioChecked = (physicalExamId, type, value = true) => {
        const radioElement = document.querySelector(`input[name="name-${physicalExamId}"][value="${type}"]`);
        if (radioElement) radioElement.checked = value
    }

    const handlePatientPhysicalExam = async (from = 'template', list = [], physicalExam, value = "") => {
        const radioElement = document.querySelector(`input[name="name-${physicalExam?._id}"]:checked`);
        let description = ''
        if (from == "template") {
            list?.map((exam) => {
                if (description?.length > 0) {
                    description = description + "\n" + exam?.desc
                }
                else description = exam?.desc
            })
        }
        else if (from == "text-area") {
            description = value
        }
        setTextAreaValue(description, physicalExam?._id)
        let checkAlreadyAdded = patientPhysicalExams?.findIndex(obj => obj?.physicalExam === physicalExam?._id);
        // add -------------------------------------------------
        if (checkAlreadyAdded == -1) {
            await prescriptionAddSubDocument('physicalExams', { physicalExam: physicalExam?._id, displayName: physicalExam?.displayName, type: radioElement.value || "notExamed", desc: description }, visitDetails?._id)
                .then((res) => {
                    if (res.status === 200) {
                        updateState(res?.data)
                        setPatientPhysicalExams(res?.data?.physicalExams)
                    }
                })
                .catch((e) => { })
                .finally(() => { setIsLoading(false) })
        }
        // update -------------------------------------------------
        else {
            await prescriptionUpdateSubDocument("physicalExams", { _id: patientPhysicalExams[checkAlreadyAdded]['_id'], [`physicalExams.$.desc`]: description, [`physicalExams.$.type`]: radioElement.value }, visitDetails?._id).
                then((res) => {
                    if (res.status == 200) {
                        updateState(res?.data)
                        setPatientPhysicalExams(res?.data?.physicalExams)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally()
        }
        setTemplateShow(false)
    }

    const updateWithDelay = async (from = 'template', list = [], physicalExam, value = "") => {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await handlePatientPhysicalExam(from, list, physicalExam, value)
        }, 1000);
    }


    const removePhysicalExam = async (id) => {
        let checkAlreadyAdded = patientPhysicalExams?.findIndex(obj => obj?.physicalExam === id);
        if (checkAlreadyAdded != -1) {
            await prescriptionRemoveSubDocument('physicalExams', { _id: patientPhysicalExams[checkAlreadyAdded]["_id"] }, visitDetails?._id)
                .then((res) => {
                    if (res?.status === 200) {
                        updateState(res?.data)
                        setPatientPhysicalExams(res?.data?.physicalExams)
                    }
                }).finally(() => { setIsLoading(false) })
        }
    }


    const patientDataShow = (patientPhysicalExams) => {
        patientPhysicalExams?.map((exam) => {
            setTextAreaValue(exam?.desc, exam?.physicalExam)
            setRadioChecked(exam?.physicalExam, exam?.type, true)
        })
    }

    const reset = (doctorPhysicalExamList) => {
        doctorPhysicalExamList?.map((exam) => {
            setTextAreaValue("", exam?._id)
            setRadioChecked(exam?._id, 'notExamed', true)
        })
    }

    useEffect(() => {
        if (doctorPhysicalExamList?.length > 0 && patientPhysicalExams) {
            if (patientPhysicalExams) {
                patientDataShow(patientPhysicalExams)
            }
        }
    }, [patientPhysicalExams, doctorPhysicalExamList])

    const physicalExamAddModal = (physicalExam) => {
        setPhysicalExamAdd(true)
        setUpdatePhysicalExam(physicalExam)
    }

    const physicalExamDelete = async (exam) => {
        console.log(exam)

        await delWithBody(`/physical-exam/delete/${exam?._id}`, { "doctorId": visitDetails?.doctor })
            .then((res) => {
                if (res.status === 200) {
                    showSuccessToast("Physical exam deleted successfully")
                    getDoctorPhysicalExamList()
                }
            })
            .catch((e) => { })
            .finally(() => { setIsLoading(false) })
    }

    const setAllNormal = async (allExams = []) => {
        const payLoad = []
        allExams?.map((exam) => {
            payLoad.push({
                physicalExam: exam?._id,
                displayName: exam?.displayName||"",
                type: "normal",
                desc: exam?.defaultNormalDesc||""
            })
        })


        if (patientPhysicalExams) {
            await put(`/visit/${visitDetails?._id}`, { "physicalExams": [] })
                .then((res) => {
                    if (res.status === 200) {
                        reset(doctorPhysicalExamList)
                        updateState(res?.data)
                        setPatientPhysicalExams([{}])
                    }
                })
                .catch((e) => { })
                .finally(() => { setIsLoading(false) })
        }

        await prescriptionAddSubDocumentArray('physicalExams', payLoad, visitDetails?._id)
            .then((res) => {
                if (res.status === 200) {
                    updateState(res?.data)
                    setPatientPhysicalExams(res?.data?.physicalExams)
                }
            })
            .catch((e) => { })
            .finally(() => { setIsLoading(false) })
    }

    const setAllNotExamed = async () => {
        await put(`/visit/${visitDetails?._id}`, { "physicalExams": [] })
            .then((res) => {
                if (res.status === 200) {
                    reset(doctorPhysicalExamList)
                    updateState(res?.data)
                    setPatientPhysicalExams([{}])
                }
            })
            .catch((e) => { })
            .finally(() => { setIsLoading(false) })
    }

    return (
        <div className='physical-exam-container'>
            <div className='d-flex justify-content-between px-2 header py-0'>
                <div className='my-0 py-0 d-flex'>
                    {/* <p >Physical exams</p> */}
                    <div onClick={() => { setAllNormal(doctorPhysicalExamList) }}>
                        <p className='mr-3 button px-3' >Set all as normal</p>
                    </div>
                    <div onClick={() => { setAllNotExamed() }}>
                        <p className='button px-3'>Set all as not examed</p>
                    </div>
                </div>
                <div className='d-flex'>
                    {/* <div className=''>
                        <ReactToPrint
                            trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{
                                cursor: "pointer",
                                background: "#13AC81",
                                color: "white"
                            }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                            content={() => printRef.current}
                        />
                    </div> */}
                    <p onClick={() => {
                        setPhysicalExamAdd(true)
                        setUpdatePhysicalExam({})
                    }} className='button my-1 px-3'>Add new</p>
                </div>
            </div>
            <div className='row g-0'>
                {doctorPhysicalExamList?.length > 0 && doctorPhysicalExamList?.map((physicalExam, index) => {
                    return (
                        <div className='col-md-6 ' key={index}>
                            <div className='single-exam  border-bottom-0 m-0 p-0 mb-2'>
                                <div className=''>
                                    <div className='row title' style={{ margin: "0 0px" }}>
                                        <div className='col-md-11'>
                                            <p onClick={() => { physicalExamAddModal(physicalExam, index) }} className='title p-0 m-0 '>{physicalExam?.displayName} <FontAwesomeIcon style={{ cursor: "pointer", fontSize: "12px" }} className="mt-0 pt-0" icon={faEdit} /></p>
                                        </div>
                                        <div className='col-md-1'>
                                            <p onClick={() => { physicalExamDelete(physicalExam) }} className='title p-0 m-0 '> <FontAwesomeIcon style={{ cursor: "pointer", fontSize: "11px" }} className="mt-0 pt-0 delete" icon={faTrash} /></p>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-between border w-100 px-2 py-2'>
                                        <div className="form-check form-check-inline">
                                            <input onClick={(e) => handleOptions(e.target.value, physicalExam, index)} className="form-check-input" type="radio" name={`name-${physicalExam?._id}`} id={`normal-${physicalExam?._id}`} value="normal" />
                                            <label className="form-check-label" htmlFor={`normal-${physicalExam?._id}`}>Normal</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onClick={(e) => handleOptions(e.target.value, physicalExam, index)} defaultChecked className="form-check-input" type="radio" name={`name-${physicalExam?._id}`} id={`notExamed-${physicalExam?._id}`} value="notExamed" />
                                            <label className="form-check-label" htmlFor={`notExamed-${physicalExam?._id}`}>Not Examed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onClick={(e) => handleOptions(e.target.value, physicalExam, index)} className="form-check-input" type="radio" name={`name-${physicalExam?._id}`} id={`abnormal-${physicalExam?._id}`} value="abnormal" />
                                            <label className="form-check-label" htmlFor={`abnormal-${physicalExam?._id}`}>Abnormal</label>
                                        </div>
                                        <div>
                                            <p onClick={() => showTemplates(physicalExam, index)} className='default-button'>Template</p>
                                        </div>
                                    </div>
                                    <div>
                                        <textarea
                                            // autoComplete="off"
                                            id={`textArea-${physicalExam?._id}`}
                                            className={`w-100`}
                                            // data-multi-title={placeholder}
                                            placeholder={"No exam"}
                                            // value={active}
                                            onChange={(e) => {
                                                updateWithDelay('text-area', [], physicalExam, e.target.value)
                                            }}
                                            rows={3}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <CustomModal
                size="lg"
                show={templateShow}
                isHeaderHide={true}
                setShow={setTemplateShow}
                autoClose={false}
            >
                <TemplateList handlePatientPhysicalExam={handlePatientPhysicalExam} doctorPhysicalExamList={doctorPhysicalExamList} setDoctorPhysicalExamList={setDoctorPhysicalExamList} visitDetails={visitDetails} setTemplateShow={setTemplateShow} templateList={selectedPhysicalExam} setSelectedPhysicalExam={setSelectedPhysicalExam}></TemplateList>
            </CustomModal>
            <CustomModal
                size="md"
                show={physicalExamAdd}
                isHeaderHide={true}
                setShow={setPhysicalExamAdd}
                autoClose={false}
            >
                <PhysicalExamAdd updatePhysicalExam={updatePhysicalExam} doctorPhysicalExamList={doctorPhysicalExamList} visitDetails={visitDetails} setDoctorPhysicalExamList={setDoctorPhysicalExamList} setPhysicalExamAdd={setPhysicalExamAdd}></PhysicalExamAdd>
            </CustomModal>

            <div className='d-none'>
                <div ref={printRef} className='full-prescription'>
                    <PrescriptionHeader
                        isHeader={isHeader}
                        info={{}} drDetails={doctorDetails || []}></PrescriptionHeader>
                    <PhysicalExamPrint isHeader={isHeader} patientPhysicalExams={patientPhysicalExams}></PhysicalExamPrint>
                </div>
            </div>
        </div>
    )
}

export default PhysicalExam
