import React, { useEffect } from 'react'
import "./visit-history.scss"
import { useDispatch, useSelector } from 'react-redux';
import { getAllVisit } from '../../../store/slices/visitSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCapsules, faPills } from '@fortawesome/free-solid-svg-icons';
import { post } from '../../../utils/fetchAPI';
import { showErrorToast, showSuccessToast } from '../../../utils/toaster-alert';
import { formatDate } from '../../../utils/common-funtions/common';

const VisitHistory = ({ setSelectedOption, patientId, visitDetails, updateState }) => {
    let { visits, isVisitLoading } = useSelector(
        (state) => state.visit
    );

    const dispatch = useDispatch()
    useEffect(() => {
        if (visitDetails?.patient || patientId)
            dispatch(getAllVisit(visitDetails?.patient || patientId))
    }, [])

    const previousMedicineAdd = async (previousVisitId) => {
        // /visit/add-prev-med/:visitId
        await post(`visit/add-prev-med/${visitDetails?._id}`, { prevVisitId: previousVisitId })
            .then((res) => {
                if (res.status == 200) {
                    if (res?.data?.errors) {
                        showErrorToast(res?.data?.errors[0]?.msg)
                    } else {
                        showSuccessToast("Previous visit medicine added to current prescription")
                        updateState(res?.data)
                        setSelectedOption({ id: 4, name: "Prescription" },)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally()
    }

    return (
        <div className='visit-history'>
            {visits && visits?.map((visit, index) => {
                return (
                    <div key={index} className='individual-visit-history'>
                        <div className='history-header d-flex justify-content-between'>
                            <label>Date: {visit?.appointmentDate?formatDate(visit?.appointmentDate?.slice(0, 10)):formatDate(visit?.createdAt?.slice(0, 10))}</label>
                            <button onClick={() => { previousMedicineAdd(visit?._id) }} style={{ fontSize: "13px", fontWeight: "600" }} className='btn btn-success w-100 py-0 my-0'>Use this Rx for current visit <FontAwesomeIcon className="mt-0 pt-0" icon={faCapsules} /></button>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 pt-1 border-right'>
                                <span className='d-block ml-1'>Attending: <strong>{visit?.doctorName}</strong></span>
                                <div className=''>
                                    <p className='title'>Reason for visit: <span className='content'>{visit?.reasonForVisit}</span></p>

                                </div>
                                <div className=''>
                                    <p className='title'>History of preset illness: <span className='content'>{visit?.historyOfPresetIllness}</span></p>
                                </div>
                            </div>
                            {/* <>{console.log(visit?.prescriptions?.medicines,"check")}</> */}
                            <div className='col-md-6'>
                                <small className='title mx-0 px-0 border-bottom'>Medicine list</small>
                                {
                                    visit?.medicines?.map((medicine, i) => {
                                        return <div key={i} className='d-flex'>
                                            <small>  <FontAwesomeIcon size='xs' className='mt-1 p-0 m-0 mb-2' icon={faPills} /></small>
                                            <small key={index} className=' px-1' style={{ fontSize: "14px", fontStyle: "italic", color: "#088aab" }}>{medicine?.name}</small>
                                            <small key={index} className='px-1' style={{ fontSize: "12px", fontStyle: "italic" }}> {medicine?.sig}</small>
                                            <small key={index} className='px-1' style={{ fontSize: "12px", fontStyle: "italic" }}> ( {medicine?.duration} )</small>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default VisitHistory
