import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./diagnosis.scss"
import { Form, Formik } from 'formik';
import { visitUpdate } from '../../../service/api/visit';
import { Icd11 } from '../../common/icd11/icd11';

const Diagnosis = ({ visitDetails = {}, updateState }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const timeoutIdRef = useRef(null)
    const setInfoFun = (key, value) => {
        // let temp = { ...info };
        // setInfo({ ...temp, [key]: value });
        clearTimeout(timeoutIdRef.current);
        let temp = { ...info, [key]: value };
        setInfo(temp);

        timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1000);
    };



    const onSubmit = async (values) => {
        // values.provisionalDx = info.provisionalDx;
        // values.differentialDx = info.differentialDx;
        // values.patient = patientId
        updateComplaint(values)
    };

    const setProvisionalDiagnosis = async (value) => {
        let values = {}
        values.provisionalDx = info?.provisionalDx + (info?.provisionalDx ? "\n" : "") + value;
        values.differentialDx = info.differentialDx;
        setInfo(values)
        await updateComplaint(values)
    }

    const setDifferentialDiagnosis = async (value) => {
        let values = {}
        values.provisionalDx = info?.provisionalDx;
        values.differentialDx = info?.differentialDx + (info?.differentialDx ? "\n" : "") + value;
        setInfo(values)
        await updateComplaint(values)
    }


    function updateComplaint(values) {
        setIsSubmitting(true)
        visitUpdate(values, visitDetails?._id)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    updateState(res.data)
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.provisionalDx = info.provisionalDx;
            values.differentialDx = info.differentialDx;
            updateComplaint(values)

        }
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);




    useMemo(() => {
        if (visitDetails) {
            setInfo({ provisionalDx: visitDetails?.provisionalDx || "", differentialDx: visitDetails?.differentialDx || "" });
        }
    }, [visitDetails])

    return (
        <div className='diagnosis-section'>
            {/* <div className='p-0 m-0 d-flex'>
                <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
            </div> */}
            {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
                {(formik) => (
                    <div className="">
                        <Form
                            action=""
                            id=""
                            className="custom-form input-section position-relative"
                            encType="multipart/form-data"
                        >
                            <div className="row  ">

                                <div className="col-6 col-md-12  mb-0 pb-0 ">
                                    <Icd11 placeHolder="Search provisional diagnosis" getValue={setProvisionalDiagnosis}></Icd11>
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                        Provisional Diagnosis
                                    </label>
                                    <div className="d-flex">
                                        {" "}
                                        <textarea
                                            className="form-control  w-100"
                                            autoComplete="off"
                                            type="text"
                                            rows={8}
                                            placeholder="Provisional Diagnosis"
                                            value={info?.provisionalDx || ""}
                                            onChange={(e) => {
                                                setInfoFun("provisionalDx", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-md-12  mb-0 pb-0 mt-5">
                                    <Icd11 placeHolder="Search differential diagnosis" getValue={setDifferentialDiagnosis}></Icd11>
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                        Differential Diagnosis
                                    </label>
                                    <div className="d-flex">
                                        {" "}
                                        <textarea
                                            className="form-control  w-100"
                                            autoComplete="off"
                                            type="text"
                                            rows={8}
                                            placeholder="Differential Diagnosis"
                                            value={info?.differentialDx || ""}
                                            onChange={(e) => {
                                                setInfoFun("differentialDx", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-12  text-center mt-3 d-flex">
                                    <button
                                        type="submit"
                                        className="btn submit-button  mx-auto w-25"
                                        onClick={() => {
                                        }}
                                    >
                                        Save
                                        {isSubmitting && (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                    </button>
                                </div> */}
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>}

            {/* <table className="table table-sm table-hover">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Reason For Visit</th>
                        <th scope="col">History Of Preset Illness</th>
                    </tr>
                </thead>
                <tbody>
                    {noComplaint ? (
                        <tr className="mx-2"><td>No previous history available</td></tr>
                    ) : (
                        complaints?.complaints?.map((complaint, index) => {
                            return (
                                <tr key={index}>
                                    <td>{complaint?.createdAt?.slice(0, 10)}</td>
                                    <td>{complaint?.reasonForVisit}</td>
                                    <td>{complaint?.historyOfPresetIllness}</td>
                                </tr>
                            );
                        })
                    )}
                    {isLoading && <tr><td className="mx-2">loading...</td></tr>}
                </tbody>
            </table> */}
        </div>
    )
}

export default Diagnosis
