import React, { useEffect, useMemo, useState } from "react";
import './profile.scss'
import { useLocation } from "react-router-dom";
import VerticalTab from "../../components/common/tabs/vertical/vertical-tab";
import ProfileName from "../../components/common/Profile/profile-name";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../../components/profile-info/user-info";
import EducationalInfo from "../../components/profile-info/educational-info";
import ResetPassword from "../../components/profile-info/reset-password";
import { post, put } from "../../utils/fetchAPI";
import { removeAuthInfo, setAuthInfo, setSelectedRole, setUserRoles } from "../../store/slices/authSlice";
import RichText from "../../components/common/inputs/editableSelect/rich-text/rich-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/SwalUti";
import { CustomizeHeader } from "./customizeHeader";


const Profile = () => {

  const [selectedOption, setSelectedOption] = useState({
    id: 1,
    name: "Vital signs",
  });
  const [selectedSubOption, setSelectedSubOption] = useState({
    parentId: "",
    subId: "",
  });
  const { user, userRoles, selectedRole } = useSelector((state) => state.auth);
  const [info, setInfo] = useState(user?.user);
  const [prescriptionInfo, setPrescriptionInfo] = useState({});
  const [updatedInfo, setUpdatedInfo] = useState({});
  const [passInfo, setPassInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false)
  const [prescriptionSettingUpdatedInfo, setPrescriptionSettingUpdatedInfo] = useState({})
  const [prescriptionSettingShowInfo, setPrescriptionSettingShowInfo] = useState()
  const [signatureImg, setSignatureImg] = useState([])
  const [backgroundImage, setBackgroundImage] = useState([])
  let formBody = new FormData();



  const options = info?.user?.type == "staff" ? [
    {
      id: 1,
      name: 'My Profile'
    },
    {
      id: 2,
      name: 'Reset password'
    }
  ] : [{
    id: 1,
    name: 'My Profile'
  },
  {
    id: 2,
    name: 'Reset password'
  },
  {
    id: 3,
    name: 'Prescription'
  }]
  const location = useLocation()
  const dispatch = useDispatch()

  const setInfoFun = (key, value, other) => {
    let userInfo = { ...info };
    if (other == "details") {
      setInfo({ ...userInfo, details: { ...userInfo['details'], [key]: value } });
      let detailsKey = 'details.' + key
      setUpdatedInfo({ ...updatedInfo, [detailsKey]: value })
    }
    else {
      setInfo({ ...userInfo, [key]: value });
      setUpdatedInfo({ ...updatedInfo, [key]: value });
    }
  };

  const setPassInfoFun = (key, value, other) => {
    let temp = { ...passInfo };
    setPassInfo({ ...temp, [key]: value });
  };

  const handleSubmit = async () => {
    let userInfo = JSON.parse(localStorage.getItem('user'))
    setIsLoading(true)

    for (var key in updatedInfo) {
      if (updatedInfo.hasOwnProperty(key)) {
        formBody.append(key, updatedInfo[key]);
      }
    }
    if (signatureImg.length > 0) {
      formBody.append("signature", signatureImg[0]);
    }
    if (backgroundImage.length > 0) {
      formBody.append("backgroundImage", backgroundImage[0]);
    }

    await post('user/update', formBody)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false)
          dispatch(setAuthInfo({ token: userInfo?.token, user: res?.data }))
          Toast.fire({
            icon: '',
            title: "Data saved successfully"
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  }
  // const handlePrescriptionSubmit = async () => {
  //   let userInfo = JSON.parse(localStorage.getItem('user'))
  //   setIsLoading(true)
  //   await post('user/update', prescriptionSettingUpdatedInfo)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsLoading(false)
  //         dispatch(setAuthInfo({ token: userInfo?.token, user: res?.data }))
  //         Toast.fire({
  //           icon: '',
  //           title: "Prescription data saved successfully"
  //         })
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false)
  //     });
  // }
  const handlePrescriptionSubmit = async () => {
    let userInfo = JSON.parse(localStorage.getItem('user'))
    setIsLoading(true)
    await put(`user/role/update/${selectedRole?._id}`, prescriptionSettingUpdatedInfo)
      .then((res) => {
        if (res.status === 200) {
          let data = { ...selectedRole, config: res?.data?.config || selectedRole?.config }
          let roles = [...userRoles]
          roles[getIndexById(data?._id)] = data
          setIsLoading(false)
          dispatch(setSelectedRole(data))
          dispatch(setUserRoles(roles))
          Toast.fire({
            icon: '',
            title: "Prescription data saved successfully"
          })
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });
  }

  function getIndexById(id) {
    return userRoles.findIndex(item => item._id === id);
  }
  const handleResetFun = async () => {
    setIsLoading(true)
    await post('user/update', passInfo)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      })
      .finally(() => {
        setIsLoading(false)
      });

  }
  const handleLogout = () => {
    dispatch(removeAuthInfo())
    localStorage.clear();
  };
  const prescriptionSettingSetInfoFun = (key, value) => {
    // setPrescriptionSettingUpdatedInfo({ ...prescriptionSettingUpdatedInfo, [`details.config.${key}`]: value })
    setPrescriptionSettingUpdatedInfo({ ...prescriptionSettingUpdatedInfo, [`config.${key}`]: value })
    setPrescriptionSettingShowInfo({ ...prescriptionSettingShowInfo, [key]: value })
  }

  useMemo(() => {
    if (selectedRole?._id) {
      setPrescriptionSettingShowInfo(selectedRole?.config)
      setPrescriptionInfo({ prescriptionFooter: selectedRole?.config?.prescriptionFooter || "", prescriptionHeader: selectedRole?.config.prescriptionHeader || "" })
    }
  }, [selectedRole])


  return <section className="profile-page" style={{ marginLeft: "71px" }}>
    <div className="menu">
      <div className="p-1 d-flex pt-2">
        <ProfileName></ProfileName>
        <p className="mx-2 mt-1" style={{ fontSize: '14px', fontWeight: "600" }}>{user?.user?.name}</p>
        <button
          type="submit"
          className="btn save-button submit-button-position mt-1 ml-auto  w-25"
          onClick={() => { handleLogout() }}
        >
          <FontAwesomeIcon
            className="px-1"
            size="1x"
            icon={faSignOut}
          />
        </button>
      </div>
      <VerticalTab
        backUrl={`/waiting-room${location?.search}`}
        setSelectedSubOption={setSelectedSubOption}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        selectedSubOption={selectedSubOption}
        options={options}
        tabTitle={"Option"}
      ></VerticalTab>
    </div>
    <div className="main-content pt-4">

      {selectedOption?.id == 1 && <div className="row no-gutters">
        <div className="col-md-6 border-right">
          <UserInfo isSubmitting={isLoading} handleSubmit={handleSubmit} setInfo={setInfo} info={info} setInfoFun={setInfoFun} selectedOption={selectedOption}></UserInfo>
        </div>
        <div className="col-md-6">
          <EducationalInfo backgroundImage={backgroundImage} setBackgroundImage={setBackgroundImage} signatureImg={signatureImg} setSignatureImg={setSignatureImg} isSubmitting={isLoading} handleSubmit={handleSubmit} setInfo={setInfo} info={info} setInfoFun={setInfoFun} selectedOption={selectedOption}></EducationalInfo>
        </div>
      </div>}

      {selectedOption?.id == 2 && <div className="row">
        <div className="col-md-6 border-right pt-4">
          <ResetPassword handleSubmit={handleResetFun} setInfo={setPassInfo} info={passInfo} setInfoFun={setPassInfoFun} selectedOption={selectedOption}></ResetPassword>
        </div>
      </div>}

      {selectedOption?.id == 3 && <div className="">

        <div className="d-flex pb-2">
          <p className="mx-3 pb-0 mb-0" style={{ fontSize: '14px', fontWeight: "600" }}>Prescription settings</p>
          <button
            type="submit" className="btn save-button submit-button-position  w-25" onClick={() => { handlePrescriptionSubmit() }} >
            Save
            {isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
          </button>
        </div>
        <div className=" px-3  border mx-3 mb-4">
          <div className="d-flex mt-2" onClick={() => { prescriptionSettingSetInfoFun("visionInPrescription", !prescriptionSettingShowInfo?.visionInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.visionInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Vision section show in prescription</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("prescriptionRxTile", !prescriptionSettingShowInfo?.prescriptionRxTile || false) }}>
            <input className="" checked={prescriptionSettingShowInfo?.prescriptionRxTile || false} type="checkBox"></input>
            <label className=" mx-2">Prescription RX title Show</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("prescriptionDivider", !prescriptionSettingShowInfo?.prescriptionDivider || false) }}>
            <input checked={prescriptionSettingShowInfo?.prescriptionDivider || false} type="checkBox"></input>
            <label className=" mx-2">Prescription Divider Show</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("prescriptionHeaderInPrint", !prescriptionSettingShowInfo?.prescriptionHeaderInPrint || false) }}>
            <input checked={prescriptionSettingShowInfo?.prescriptionHeaderInPrint || false} type="checkBox"></input>
            <label className=" mx-2">Prescription header show in print</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("prescriptionFooterInPrint", !prescriptionSettingShowInfo?.prescriptionFooterInPrint || false) }}>
            <input checked={prescriptionSettingShowInfo?.prescriptionFooterInPrint || false} type="checkBox"></input>
            <label className=" mx-2">Prescription footer show in print</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("signatureWithImage", !prescriptionSettingShowInfo?.signatureWithImage || false) }}>
            <input checked={prescriptionSettingShowInfo?.signatureWithImage || false} type="checkBox"></input>
            <label className=" mx-2">Signature field with image</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("signatureWithoutImage", !prescriptionSettingShowInfo?.signatureWithoutImage || false) }}>
            <input checked={prescriptionSettingShowInfo?.signatureWithoutImage || false} type="checkBox"></input>
            <label className=" mx-2">Signature field without image</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("prescriptionBackgroundImage", !prescriptionSettingShowInfo?.prescriptionBackgroundImage || false) }}>
            <input checked={prescriptionSettingShowInfo?.prescriptionBackgroundImage || false} type="checkBox"></input>
            <label className=" mx-2">Prescription background image</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("isPhysicalExamShowInPrescription", !prescriptionSettingShowInfo?.isPhysicalExamShowInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.isPhysicalExamShowInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Physical exam in prescription print</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("pastMedicalHistoryInPrescription", !prescriptionSettingShowInfo?.pastMedicalHistoryInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.pastMedicalHistoryInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Past medical history in prescription</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("familyHistoryInPrescription", !prescriptionSettingShowInfo?.familyHistoryInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.familyHistoryInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Family history in prescription</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("surgicalHistoryInPrescription", !prescriptionSettingShowInfo?.surgicalHistoryInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.surgicalHistoryInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Surgical history in prescription</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("allergyHistoryInPrescription", !prescriptionSettingShowInfo?.allergyHistoryInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.allergyHistoryInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Allergy history in prescription</label>
          </div>
          <div className="d-flex" onClick={() => { prescriptionSettingSetInfoFun("socilHistoryInPrescription", !prescriptionSettingShowInfo?.socilHistoryInPrescription || false) }}>
            <input checked={prescriptionSettingShowInfo?.socilHistoryInPrescription || false} type="checkBox"></input>
            <label className=" mx-2">Social history in prescription</label>
          </div>
          <div className="mb-2">
            <label className="mt-2 mx-2 d-block">Patient details position</label>
            <select
              className="mx-2"
              onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionNamePosition", e.target.value) }}
              value={prescriptionSettingShowInfo?.prescriptionNamePosition}
            >
              <option value={"1"}>Details on top</option>
              <option value={"2"}>Details in left side </option>
              <option value={"3"}>Customize details on top </option>
            </select>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className=" d-block">Prescription Default font Size (pt)</label>
            <input value={prescriptionSettingShowInfo?.prescriptionDefaultFontSize} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionDefaultFontSize", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className=" d-block">Prescription Medicine Font Size (pt)</label>
            <input value={prescriptionSettingShowInfo?.medicineFontSize} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("medicineFontSize", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className=" d-block">Prescription header height (pixel)</label>
            <input value={prescriptionSettingShowInfo?.prescriptionHeaderHeight} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionHeaderHeight", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription body height (pixel)</label>
            <input value={prescriptionSettingShowInfo?.prescriptionBodyHeight} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionBodyHeight", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription body left padding (pixel)</label>
            <input value={prescriptionSettingShowInfo?.prescriptionBodyLeftPadding} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionBodyLeftPadding", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription Margin (Ex: top right bottom left ) </label>
            <input value={prescriptionSettingShowInfo?.prescriptionMargin} type="text" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionMargin", e.target.value) }}></input>
          </div>
          <div className="mb-2">
            <label className="mt-2 mx-2 d-block">Prescription medicine design</label>
            <select
              className="mx-2"
              onChange={(e) => { prescriptionSettingSetInfoFun("medicineSectionDesign", e.target.value) }}
              value={prescriptionSettingShowInfo?.medicineSectionDesign}
            >
              <option value={"1"}>Design 1</option>
              <option value={"2"}>Design 2 </option>
            </select>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription body left section width (%)</label>
            <input value={prescriptionSettingShowInfo?.prescriptionBodyLeftSectionWidth} type="number" onChange={(e) => { prescriptionSettingSetInfoFun("prescriptionBodyLeftSectionWidth", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription header background color</label>
            <input value={prescriptionSettingShowInfo?.headerBackgroundColor} type="color" onChange={(e) => { prescriptionSettingSetInfoFun("headerBackgroundColor", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Prescription footer background color</label>
            <input value={prescriptionSettingShowInfo?.footerBackgroundColor} type="color" onChange={(e) => { prescriptionSettingSetInfoFun("footerBackgroundColor", e.target.value) }}></input>
          </div>
          <div className="mb-2 mt-2 mx-2 ">
            <label className="d-block">Next visit instructions</label>
            <textarea style={{ outline: "none", fontSize: "13px" }} className="w-75" rows={7} value={prescriptionSettingShowInfo?.nextVisitInstructions} type="text" onChange={(e) => { prescriptionSettingSetInfoFun("nextVisitInstructions", e.target.value) }}></textarea>
          </div>
        </div>

        {/* nextVisitInstructions */}

        <div className="my-5 mx-4">
          <CustomizeHeader prescriptionSettingShowInfo={prescriptionSettingShowInfo} prescriptionSettingSetInfoFun={prescriptionSettingSetInfoFun}></CustomizeHeader>
        </div>
        {/* prescriptionDivider */}


        {/* <div className="d-flex">
          <p className="mx-3 pb-0 mb-0" style={{ fontSize: '14px', fontWeight: "600" }}>Header section</p>
          <button
            type="submit" className="btn save-button submit-button-position  w-25" onClick={() => { handlePrescriptionSubmit() }} >
            Save
            {isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
          </button>
        </div> */}
        <div className="border-right  mx-3 mt-1 mb-4">
          <RichText info={prescriptionInfo} keyName="prescriptionHeader" handleFormValues={(val, key) => {
            prescriptionSettingSetInfoFun(key, val)
            setPrescriptionInfo({ ...prescriptionInfo, [key]: val })
          }}></RichText>
        </div>
        {/* <div className="d-flex">
          <p className="mx-3 pb-0 mb-0" style={{ fontSize: '14px', fontWeight: "600" }}>Footer section</p>
          <button
            type="submit" className="btn save-button submit-button-position  w-25" onClick={() => { handlePrescriptionSubmit() }} >
            Save
            {isLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)}
          </button>
        </div> */}
        <div className="border-right pt-0 mx-3 my-1">
          <RichText info={prescriptionInfo} keyName="prescriptionFooter" handleFormValues={(val, key) => {
            prescriptionSettingSetInfoFun(key, val)
            setPrescriptionInfo({ ...prescriptionInfo, [key]: val })
          }}></RichText>
        </div>

      </div>}

    </div>
  </section >;
};

export default Profile;
