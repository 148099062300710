import React from 'react'
import { useRef } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import "./rich-text.scss"
const RichText = ({ info, keyName = "keyName", is_mandatory = false, handleFormValues }) => {
    const editorRef = useRef(null);
    return (
        <div className='rich-text'>
            {/* <label htmlFor={name} className={`d-block ${is_mandatory == 1 ? "required" : ""}`}>{label} </label> */}
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY||""}
                onInit={(evt, editor) => editorRef.current = editor}
                onEditorChange={(e) => {
                    handleFormValues(e, keyName)
                }}
                value={info[keyName] || ""}
                init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                        'advlist', 'lineheight', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                    ],
                    toolbar: [
                        'casechange blocks fontsize | undo redo | removeformat | lineheightselect ',
                        ' alignleft aligncenter alignright alignjustify | bold italic underline | forecolor backcolor',
                        'bullist numlist checklist outdent indent | table link image imagetools'
                    ],
                    lineheight_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 36pt",
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px ;line-height: .4;}',
                    images_upload_url: 'postAcceptor.php',
                    automatic_uploads: false
                }}
            />
        </div>

    )
}

export default RichText