import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import "./medicine-sig-form.scss"
import { addDoctorMedicine, createDoctorMedicine, updateDoctorMedicine } from '../../../../../service/api/doctor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorMedicineList } from '../../../../../store/slices/prescriptionSlice';
import { medicineUpdate } from '../../../../../service/api/patient-api';
import { string } from "yup";
import { del, post } from '../../../../../utils/fetchAPI';
import { prescriptionUpdateSubDocument } from '../../../../../service/api/prescription';
import { Toast } from '../../../../../utils/SwalUti';
import { templateUpdateSubDocument } from '../../../../../service/api/template';
import { durationTileData, frequencyData, instructions, repeatData, routesData } from '../../../../../utils/static-data/data';
import { setAuthInfo } from '../../../../../store/slices/authSlice';

const MedicineSigForm = ({
    setPrescribedMedicines,
    visitId,
    updateState,
    isDocMedicineUp = false,
    setIsDocMedicineUp,
    isDoubleClick = false,
    setIsDoubleClick,
    setDocMedicineShow,
    setSearchString,
    searchString = "",
    doctorId,
    medicineAddToPrescription,
    setShow,
    selectedMedicine,
    templateId
}) => {
    const { user } = useSelector((state) => state.auth);
    const [language, setLanguage] = useState("english")
    const [info, setInfo] = useState({
        name: selectedMedicine?.name || searchString || "",
        take: selectedMedicine?.take || "",
        route: selectedMedicine?.route || "",
        frequency: selectedMedicine?.frequency || "",
        instruction: selectedMedicine?.instruction || "",
        repeat: selectedMedicine?.repeat?.split("/")[0] || "1",
        repeat_time: selectedMedicine?.repeat?.split("/")[1]?.replace("s", "") || "day",
        duration: selectedMedicine?.duration?.split("/")[0] || "",
        duration_time: selectedMedicine?.duration?.split("/")[1],
        power: selectedMedicine?.power || "",
        type: selectedMedicine?.type || "",
        generic: selectedMedicine?.generic || "",
        manufacturer: selectedMedicine?.manufacturer || "",
        addToDoctorMedicine: false
    });

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [sig, setSig] = useState(selectedMedicine?.sig || "")
    const [isMedicineSave, setIsMedicineSave] = useState(false)
    const [showMore, setShowMore] = useState({ generic: 45, power: 45 })
    // const [repeatTime, setRepeatTime] = useState(info?.repeat?.split(" ")[1] || "day(s)")
    // const [durationTime, setDurationTime] = useState(info?.duration?.split("/")[1] || "day(s)")
    const [enableDelete, setEnableDelete] = useState(false)
    const dispatch = useDispatch()

    const onSubmit = (values) => {
        values.name = info?.name
        values.take = info?.take || ""
        values.route = info?.route || ""
        values.frequency = info?.frequency || ""
        values.instruction = info?.instruction || ""
        values.sig = sig || ""

        values.repeat = info?.repeat + "/" + ((info?.repeat > 1 || info?.repeat > "১") ? (info?.repeat_time + "s") : info?.repeat_time)
        // values.duration = info?.duration + "/" + ((info?.duration > 1 || info?.duration > "১") ? (info?.duration?.length > 0 ? (info.duration_time + "s") : "") : (info?.duration?.length > 0 ? info.duration_time : ""))
        if (info?.duration == "continue" || info?.duration == "চলবে") {
            values.duration = info?.duration
        }
        else values.duration = info?.duration + (info?.duration_time ? "/" + info?.duration_time : "")
        values.power = info?.power
        values.type = info?.type
        values.generic = info?.generic
        values.manufacturer = info?.manufacturer
        values.addToDoctorMedicine = info?.addToDoctorMedicine

        if (selectedMedicine?._id && !isDocMedicineUp && !isDoubleClick && !isMedicineSave) {
            values.medicineId = selectedMedicine?._id
            medicineAddToPrescription(values)
            setDocMedicineShow(true)
            setShow(false)
            setIsDoubleClick(false)
            setSearchString("")
        } else if (!isDocMedicineUp && !isDoubleClick) {
            if (selectedMedicine?.doctorMedicineId) {
                values.doctorMedicineId = selectedMedicine?.doctorMedicineId
            }
            const { addToDoctorMedicine, ...rest } = values
            values = rest
            createDoctorMedicine(doctorId, values)
                .then((res) => {
                    if (res?.status === 200) {

                        values.doctorMedicineId = res?.data?._id
                        medicineAddToPrescription(values)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setDocMedicineShow(true)
                    setShow(false)
                    setIsDoubleClick(false)
                    setSearchString("")
                }
                );
        } else if (isDocMedicineUp && !isDoubleClick) {
            const { addToDoctorMedicine, doctorId, ...rest } = values
            values = rest
            updateDoctorMedicine(selectedMedicine?._id, values)
                .then((res) => {
                    if (res?.status === 200) {
                        const payload = { doctorId: user?.user?._id, search: "" }
                        dispatch(getDoctorMedicineList(payload))
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setDocMedicineShow(true)
                    setShow(false)
                    setIsDoubleClick(false)
                    setSearchString("")
                    setIsDocMedicineUp(false)
                }
                );
        } else if (isDoubleClick && !isDocMedicineUp) {
            values.prescribedMedicineId = selectedMedicine?._id
            if (selectedMedicine?.doctorMedicine?.length > 0) values.doctorMedicineId = selectedMedicine?.doctorMedicine
            updateMedicine(selectedMedicine?._id, values, visitId)
        }


    }

    const durationFormatFun = (duration) => {
        let ary = duration?.split('/')
        return ary[0] || ""
    }
    const updateMedicine = async (medicineId, values, visitId) => {

        let payload = {}
        payload["_id"] = medicineId || ""
        payload["medicines.$.name"] = values?.name || ""
        payload["medicines.$.take"] = values?.take || ""
        payload["medicines.$.frequency"] = values?.frequency || ""
        payload["medicines.$.instruction"] = values?.instruction || ""
        payload["medicines.$.sig"] = values?.sig || ""
        payload["medicines.$.repeat"] = values?.repeat || ""
        payload["medicines.$.duration"] = values?.duration || ""
        payload["medicines.$.power"] = values?.power || ""
        payload["medicines.$.type"] = values?.type || ""
        payload["medicines.$.generic"] = values?.generic || ""
        payload["medicines.$.manufacturer"] = values?.manufacturer || ""

        if (visitId) {
            await prescriptionUpdateSubDocument("medicines", payload, visitId).then((res) => {
                if (res.status == 200) {
                    setPrescribedMedicines(res?.data?.medicines)
                    updateState(res?.data)
                    setDocMedicineShow(true)
                    setShow(false)
                    setIsDoubleClick(false)
                    setSearchString("")
                    setIsDocMedicineUp(false)
                    setEnableDelete(false)
                    const payload = { doctorId: user?.user?._id, search: "" }
                    dispatch(getDoctorMedicineList(payload))
                }
            })
                .catch((error) => {
                    console.log(error);
                })
                .finally()
        }
        else if (templateId) {
            await templateUpdateSubDocument("medicines", payload, templateId).then((res) => {
                if (res.status == 200) {
                    setPrescribedMedicines(res?.data?.medicines)
                    setDocMedicineShow(true)
                    setIsDoubleClick(false)
                    setSearchString("")
                    setIsDocMedicineUp(false)
                    setEnableDelete(false)
                    const payload = { doctorId: user?.user?._id, search: "" }
                    dispatch(getDoctorMedicineList(payload))
                    setShow(false)
                }
            })
                .catch((error) => {
                    console.log(error);
                })
                .finally()
        }

    }


    function getType(type) {
        if (type?.includes('Tablet') || type?.includes('tablet')) {
            return language == 'bangla' ? 'টি ট্যাবলেট' : 'tablet'
        } else if (type?.includes('Capsule') || type?.includes('capsule')) {
            return language == 'bangla' ? 'টি ক্যাপসুল' : 'Capsule'
        } else if (type?.includes('Drop') || type?.includes('drop')) {
            return language == 'bangla' ? 'ফোটা' : 'Drop'
        } else if (type?.includes('Syrup') || type?.includes('syrup')) {
            return language == 'bangla' ? 'চামচ' : 'spoon'
        } else if (type?.includes('Suspension') || type?.includes('Solution')
            || type?.includes('Powder') || type?.includes('suspension') || type?.includes('solution')
            || type?.includes('powder')) {
            return ""
        } else if (type?.includes('Injection') || type?.includes('Infusion') || type?.includes('injection') || type?.includes('infusion')) {
            return language == 'bangla' ? 'ইনজেকশন' : 'injection'
        } else {
            return ""
        }
    }


    const setInfoFun = (key, value) => {
        let temp = { ...info };

        if (value == "চলবে" || value == "continue") {
            setInfo({ ...temp, [key]: value, duration: value });
        }
        else {
            setInfo({ ...temp, [key]: value });
        }
        if (key == "repeat" || key == "route" || key == "frequency" || key == "take" || key == "repeat_time" || key == "instruction") {
            let sig = ''
            const take = key == "take" ? value : info?.take
            const route = key == "route" ? value : info?.route
            const frequency = key == "frequency" ? value : info?.frequency
            const repeat = key == "repeat" ? value : info?.repeat
            const r_time = key == "repeat_time" ? value : info?.repeat_time
            const instruction = key == "instruction" ? value : info?.instruction

            const type = getType(info?.type || selectedMedicine?.type)

            // sig = (take ? `${take} ${type}` : "")
            // + (route ? " " + `${route}` : "")
            // + (frequency ? " " + `(${frequency})` : "")
            // + (repeat ? " " + `${(repeat == 1 || repeat == '১') ?  "everyday" : `every ${repeat} ${(repeat == 1 || repeat == '১') ? r_time : r_time + "s"}`}` : "")

            sig = (take ? `${take} ${type}` : "")
                + (route ? " " + `${route}` : "")
                + (frequency ? " " + `(${frequency})` : "")
                + " " + instruction
            setSig(sig)
        }
    };


    const showMoreFun = (key, value) => {
        let temp = { ...showMore }
        setShowMore({ ...temp, [key]: value })
    }

    useEffect(() => {
        if (!selectedMedicine?.name && !selectedMedicine?.medicineName) setInfoFun("medicineName", searchString);
        if (user?.user?.details?.config?.language)
            setLanguage(user?.user?.details?.config?.language)
    }, [])

    const medicineDelete = async () => {
        await del(`doctor/delete-new-medicine/${selectedMedicine?._id}`)
            .then((res) => {
                if (res.status == 200) {
                    setDocMedicineShow(true)
                    setShow(false)
                    setIsDoubleClick(false)
                    setSearchString("")
                    setIsDocMedicineUp(false)
                    const payload = { doctorId: user?.user?._id, search: "" }
                    dispatch(getDoctorMedicineList(payload))
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally()
    }



    const handleLanguage = async (checked) => {
        let language = "english"
        if (checked) language = 'bangla'
        else language = "english"
        let userInfo = JSON.parse(localStorage.getItem('user'))
        let key = `details.config.language`
        setLanguage(language)
        await post('user/update', { [key]: language })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(setAuthInfo({ token: userInfo?.token, user: res?.data }))
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <div>
            <Formik
                initialValues={{}}
                onSubmit={onSubmit}
            >
                {(formik) => (
                    <div className="medicine-sig-from-section">
                        <div className='d-flex justify-content-between '>
                            <label className='mt-1'>Details</label>
                            <div className='d-flex'>
                                <p className=""><input checked={user?.user?.details?.config?.language == "bangla" ? true : false} className="" onChange={(e) => {
                                    handleLanguage(e.target.checked)
                                }} name="check-2" id="check-2" type="checkbox"></input></p>
                                <label htmlFor="check-2" className="mt-1" style={{ cursor: "pointer" }}>Bangla</label>
                            </div>
                        </div>
                        {(selectedMedicine?.name && (!isDocMedicineUp || selectedMedicine?.medicine)) ? <div className=" header mb-0 pb-0 pt-1 px-0 pb-2">
                            <p className='m-0 p-0'><span>Medicine name: </span><input onChange={(e) => {
                                setInfoFun("name", e.target.value);
                            }} value={info?.name}></input></p>
                            <p className='m-0 p-0'><span>Manufacturer: </span>{selectedMedicine?.manufacturer}</p>

                            <p className='m-0 p-0'><span>Medicine type: </span>{selectedMedicine?.type}</p>
                            {selectedMedicine?.power?.length > 45 ?
                                <p className='m-0 p-0' title={selectedMedicine?.power}><span>Power: </span>{selectedMedicine?.power?.slice(0, Number(showMore?.power))} <span
                                    className='cursor ' onClick={() => {
                                        showMoreFun("power", showMore?.power == selectedMedicine?.power?.length ? 45 : selectedMedicine?.power?.length)
                                    }}>{showMore?.power == selectedMedicine?.power?.length ? " show less" : "...show more"} <FontAwesomeIcon id={`social-history-icon`} size="xs"
                                        className={`mt-1 cursor ${showMore?.power == selectedMedicine?.power?.length ? "rotate-180" : ""}`}
                                        icon={faChevronDown} /></span></p> :
                                <p className='m-0 p-0' title={selectedMedicine?.power}><span>Power: </span>{selectedMedicine?.power}</p>}

                            {selectedMedicine?.generic?.length > 45 ?
                                <p className='m-0 p-0' title={selectedMedicine?.generic}><span>Generic: </span>{selectedMedicine?.generic?.slice(0, Number(showMore?.generic))}
                                    <span className='cursor ' onClick={() => {
                                        showMoreFun("generic", showMore?.generic == selectedMedicine?.generic?.length ? 45 : selectedMedicine?.generic?.length)
                                    }}>{showMore?.generic == selectedMedicine?.generic?.length ? " show less" : "...show more"} <FontAwesomeIcon id={`social-history-icon`}
                                        size="xs"
                                        className={`mt-1 cursor ${showMore?.generic == selectedMedicine?.generic?.length ? "rotate-180" : ""}`}
                                        icon={faChevronDown} /></span></p> :
                                <p className='m-0 p-0' title={selectedMedicine?.generic}><span>Generic: </span>{selectedMedicine?.generic}</p>}
                        </div> :
                            <div className='header mb-0 pb-0 pt-1 px-0 pb-2'>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">Name:</label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.name || ""} placeholder="name" onChange={(e) => {
                                            setInfoFun("name", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">Power:</label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.power || ""} placeholder="power" onChange={(e) => {
                                            setInfoFun("power", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">Type:</label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.type || ""} placeholder="type" onChange={(e) => {
                                            setInfoFun("type", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">Generic:</label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.generic || ""} placeholder="Generic" onChange={(e) => {
                                            setInfoFun("generic", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">Manufacturer:</label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.manufacturer || ""} placeholder="Manufacturer" onChange={(e) => {
                                            setInfoFun("manufacturer", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>}
                        <Form
                            action=""
                            id=""
                            className="custom-form"
                            encType="multipart/form-data"
                        >
                            <div className="row  border-top pt-2 px-2">
                                {/* <p className='p-0 my-0'>Drug details</p> */}
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="" className="form-label mt-1">
                                        Take:
                                    </label>
                                    <input className="form-control input w-50" autoComplete="off" type="text"
                                        value={info?.take || ""}
                                        placeholder="Take"
                                        onChange={(e) => {
                                            setInfoFun("take", e.target.value);
                                        }}
                                    />

                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex ">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        Route:
                                    </label>
                                    <input className="w-50  form-control input" autoComplete="off" type="text"
                                        value={info?.route || ""}
                                        placeholder="Route"
                                        onChange={(e) => {
                                            setInfoFun("route", e.target.value);
                                        }}
                                    />
                                    <select
                                        onChange={(e) => {
                                            setInfoFun("route", e.target.value);
                                        }}
                                    >
                                        <option value={""}>{language == 'bangla' ? "নির্বাচন করুন" : "Select"}</option>
                                        {routesData?.map((data, index) => {
                                            return <option key={index} value={language == 'bangla' ? data?.value_bn : data?.value_en}>{language == 'bangla' ? data?.value_bn : data?.value_en}</option>
                                        })}
                                    </select>

                                </div>
                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        Frequency:
                                    </label>
                                    <input className="w-50  form-control input" autoComplete="off" type="text"
                                        value={info?.frequency || ""}
                                        placeholder="Frequency"
                                        onChange={(e) => {
                                            setInfoFun("frequency", e.target.value);
                                        }}
                                    />
                                    <select
                                        onChange={(e) => {
                                            setInfoFun("frequency", e.target.value);
                                        }}
                                    >
                                        <option value={""}>{language == 'bangla' ? "নির্বাচন করুন" : "Select"}</option>
                                        {frequencyData?.map((data, index) => {
                                            return <option key={index} value={language == 'bangla' ? data?.value_bn : data?.value_en}>{language == 'bangla' ? data?.value_bn : data?.value_en}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        Instruction:
                                    </label>
                                    <input className="w-50  form-control input" autoComplete="off" type="text"
                                        value={info?.instruction || ""}
                                        placeholder="instruction"
                                        onChange={(e) => {
                                            setInfoFun("instruction", e.target.value);
                                        }}
                                    />
                                    <select
                                        onChange={(e) => {
                                            setInfoFun("instruction", e.target.value);
                                        }}
                                    >
                                        <option value={""}>{language == 'bangla' ? "নির্বাচন করুন" : "Select"}</option>
                                        {instructions?.map((data, index) => {
                                            return <option key={index} value={language == 'bangla' ? data?.value_bn : data?.value_en}>{language == 'bangla' ? data?.value_bn : data?.value_en}</option>
                                        })}
                                    </select>
                                </div>

                                {/* <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        Repeat:
                                    </label>
                                    <input className="w-50  form-control input" autoComplete="off"   pattern="^[0-9০-৯]+$"  type="text"
                                        value={info?.repeat?.split(" ")[0] || ""}
                                        placeholder="Repeat"
                                        onChange={(e) => {
                                            setInfoFun("repeat", e.target.value);
                                        }}
                                    />
                                    <select
                                        value={info?.repeat_time}
                                        onChange={(e) => {
                                            setInfoFun("repeat_time", e.target.value);
                                        }}>
                                        {repeatData?.map((data, index) => {
                                            return <option key={index} value={language == 'bangla' ? data?.value_bn : data?.value_en}>{language == 'bangla' ? data?.value_bn : data?.value_en}</option>
                                        })}
                                    </select>
                                </div> */}

                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        SIG:
                                    </label>
                                    <textarea className="mx-2 my-1 form-control input" autoComplete="off" type="text"
                                        value={sig || ""}
                                        required={true}
                                        placeholder="SIG"
                                        onChange={(e) => {
                                            setSig(e.target.value);
                                        }}
                                        rows={3}
                                    />

                                </div>

                                <div className="col-12 m-0 p-0 individual-input d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label mt-1">
                                        Duration:
                                    </label>
                                    <input className="w-50  form-control input" autoComplete="off" type="text"
                                        /*min="1" step="1"*/
                                        //    required={true}
                                        // pattern="^[0-9০-৯]+$"
                                        value={info?.duration?.split(" ")[0]}
                                        placeholder="Duration"
                                        onChange={(e) => {
                                            setInfoFun("duration", e.target.value);
                                        }}
                                    />
                                    <select
                                        value={info.duration_time}
                                        onChange={(e) => {
                                            setInfoFun("duration_time", e.target.value);
                                        }}>
                                        <option value={""}>{language == 'bangla' ? "নির্বাচন করুন" : "Select"}</option>
                                        {durationTileData?.map((data, index) => {
                                            return <option key={index} value={language == 'bangla' ? data?.value_bn : data?.value_en}>{language == 'bangla' ? data?.value_bn : data?.value_en}</option>
                                        })}
                                    </select>

                                </div>
                                {!isDocMedicineUp && !isDoubleClick && <div className={`save-medicine d-flex w-100 mx-0 px-0`}>
                                    <input onChange={() => {
                                        setIsMedicineSave(!isMedicineSave)
                                        setInfoFun("addToDoctorMedicine", !info?.addToDoctorMedicine);
                                    }} className='mx-0 px-0' type="checkbox" id='save-doc-medicine' name="save-doc-medicine" checked={isMedicineSave} />
                                    <label className='ml-0 align-content-center justify-content-center my-auto' htmlFor="save-doc-medicine">Save to my drug list</label><br />
                                </div>}
                                <div className="mx-auto text-center mt-2 d-flex mb-2">
                                    <button
                                        type="submit"
                                        className="btn save-button d-flex mr-4 w-100 "
                                    >
                                        <span>Save</span>
                                        {isSubmitting && (
                                            <span
                                                className="spinner-border spinner-border-sm "
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                    </button>
                                    <p

                                        className="btn close-button mx-auto w-100"
                                        onClick={() => {
                                            setShow(false)
                                            setIsDoubleClick(false)
                                            setIsDocMedicineUp(false)
                                        }}
                                    >
                                        Close
                                    </p>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
            <div className="row appointment-details pb-3 mt-2">
                <p className="col-12 px-3 py-0 my-0 py-2 d-flex"><input checked={enableDelete || ""} className="my-0 py-0 " onChange={() => {
                    setEnableDelete(!enableDelete)
                }} name="check-1" id="check-1" type="checkbox"></input> <label htmlFor="check-1" className="px-1 py-0 my-0 d-inline" style={{ cursor: "pointer" }}>Delete this
                    medicine</label></p>
                <p className="col-6 px-3 py-0 my-0 ">  <span onClick={() => {
                    medicineDelete()
                }} className={`icon ${enableDelete ? "enable" : "disable"} btn btn-danger btn-sm d-inline p-1 px-3 my-0`}>Delete <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>
                </p>
            </div>
        </div>
    )
}

export default MedicineSigForm


