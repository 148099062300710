import React, { useRef, useState } from 'react'
import CreatableSearchSelectIcd11 from '../updated-input/createble-search-select/creatable-search-select-icd-11';

export const Icd11 = ({ getValue, placeHolder = "search",title="Search from ICD11" }) => {
    const [isLabTestListLoading, setIsLabTestListLoading] = useState(false)
    const [allData, setAllData] = useState([])
    const timeoutIdRef = useRef(null)


    const handleSubmit = (value) => {
        let valueWithOutHtmlTag = removeHtmlTags(value?.title)
        getValue(valueWithOutHtmlTag)
    }

    function removeHtmlTags(inputString) {
        return inputString.replace(/<\/?[^>]+(>|$)/g, "");
    }

    const handleCreate = (value) => {
        getValue(value)
    }

    const setOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            getIcd11Value(event.target.value)
        }, 400);

    }

    const getIcd11Value = async (searchQuery) => {
        try {
            const response = await fetch(
                `https://prod-icd-api.shukhee.com/icd/entity/search?q=${searchQuery}&useFlexisearch=false&flatResults=true&highlightingEnabled=true`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "API-Version": "v2",
                        "Accept-Language": "en"
                    },
                }
            );
            const result = await response.json();
            if (result?.error == false)
                setAllData(result?.destinationEntities)
            else setAllData([])
        } catch (error) {
            console.error("Error fetching ICD data:", error);
        }

    };
    return (
        <div className='mb-1'>
            <label htmlFor="exampleInputEmail1" className="form-label pt-1 mb-1">
                {title}
            </label>
            <CreatableSearchSelectIcd11 customClass='custom-color-border-1' handleSubmit={handleSubmit} handleCreate={handleCreate} isSearching={isLabTestListLoading} setOptionFun={setOptionFun} options={allData} placeHolder={placeHolder}></CreatableSearchSelectIcd11>
        </div>
    )
}
